<template>
  <div class="app-skin-base">
    <div v-if="isLoggedIn" class="app-sidebar">
      <MainMenu />
    </div>
    <div class="app-wrap">
      <header class="header">
        <div class="app-logo-1">
              <img width="113" height="auto" :src="headerLogoPath" alt="">
        </div>
        <div class="logo-text" v-html="headerLogoText"></div>
        <LanguageSwitcher selectStyle="header__language-switcher"/>
        <div v-if="isLoggedIn" class="header-controls">
          <router-link
              v-bind:to="{
                name: 'User Edit',
                params: { id: user.id }
              }"
              class="app-sidebar-user"
            >
              {{ user.name }}
            </router-link>
          <button @click="logout" class="btn-exit">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.9364 8.84817C19.8939 8.74649 19.8331 8.65399 19.7556 8.57649L17.2564 6.07731C16.9306 5.75231 16.4039 5.75231 16.0781 6.07731C15.7522 6.40313 15.7522 6.93063 16.0781 7.25563L17.1556 8.33313H12.5005C12.0397 8.33313 11.6672 8.70645 11.6672 9.16645C11.6672 9.62645 12.0397 9.99977 12.5005 9.99977H17.1555L16.078 11.0773C15.7522 11.4031 15.7522 11.9306 16.078 12.2556C16.2405 12.4189 16.4538 12.4998 16.6672 12.4998C16.8806 12.4998 17.0939 12.419 17.2564 12.2556L19.7556 9.75641C19.8331 9.67973 19.8939 9.58723 19.9364 9.48473C20.0205 9.28149 20.0205 9.05149 19.9364 8.84817Z" fill="#C2CFE0"/>
              <path d="M14.1673 11.6667C13.7064 11.6667 13.3339 12.04 13.3339 12.5V16.6667H10.0006V3.33332C10.0006 2.96582 9.75893 2.64082 9.40643 2.535L6.51225 1.66668H13.3339V5.83336C13.3339 6.29336 13.7064 6.66668 14.1673 6.66668C14.6281 6.66668 15.0006 6.29336 15.0006 5.83336V0.833359C15.0006 0.37332 14.6281 0 14.1673 0H0.833931C0.803931 0 0.777251 0.0125 0.74811 0.0158203C0.708931 0.02 0.67311 0.0266406 0.63561 0.0358203C0.54811 0.0583203 0.468931 0.0925 0.39479 0.139141C0.37647 0.15082 0.35397 0.151641 0.33647 0.164961C0.329751 0.17 0.327251 0.17918 0.320571 0.18418C0.229751 0.25582 0.153931 0.34332 0.0989307 0.4475C0.087251 0.47 0.084751 0.49418 0.0756103 0.5175C0.0489307 0.58082 0.01979 0.6425 0.00979004 0.7125C0.00561035 0.7375 0.0131104 0.76082 0.01229 0.785C0.0114697 0.80168 0.000610352 0.81668 0.000610352 0.83332V17.5C0.000610352 17.8975 0.281431 18.2392 0.67061 18.3167L9.00393 19.9834C9.05811 19.995 9.11311 20 9.16725 20C9.35807 20 9.54557 19.9342 9.69557 19.8109C9.88807 19.6525 10.0006 19.4167 10.0006 19.1667V18.3334H14.1673C14.6281 18.3334 15.0006 17.96 15.0006 17.5V12.5C15.0006 12.04 14.6281 11.6667 14.1673 11.6667Z" fill="#C2CFE0"/>
            </svg>
          </button>
        </div>
      </header>
      <main :class="[ isLoggedIn ? 'isLogin' : '' ]">
        <router-view/>
      </main>
      <footer class="footer">
        <p>{{ footerText }}</p>
      </footer>
      <NoticeWindow ref="notification" />
      <ModalWindow ref="modalWindow" />
      <!-- <nprogress-container></nprogress-container> -->
      <!-- <ProgressBar size="small" :val="20"></ProgressBar> -->
      <vue-progress-bar></vue-progress-bar>
    </div>
    <Phone />
  </div>
</template>

<script>
import getEnv from '@/utils/env.js';
import NoticeWindow from '@/components/NoticeWindow';
import ModalWindow from '@/components/ModalWindow';
import MainMenu from '@/components/MainMenu/MainMenu.vue';
import Phone from '@/components/Phone';
import LanguageSwitcher from '@/components/LanguageSwitcher';

// import store from './store'
// import Phone from './components/Phone'

// import ProgressBar from 'vue-simple-progress'
// import NprogressContainer from 'vue-nprogress/src/NprogressContainer'

export default {
  components: {
    NoticeWindow,
    MainMenu,
    Phone,
    // ProgressBar,
    ModalWindow,
    LanguageSwitcher
    // ,
    // Phone: () => {
    //   return new Promise((resolve, reject) => {
    //     // const script = document.createElement('script')
    //     // script.onload = () => {
    //     resolve(import(Phone))
    //     // }
    //     // script.async = true
    //     // script.src = '//mail.communigate.ru/SkinFiles/mail.communigate.ru/samoware/ximssclient.js'
    //     // document.head.appendChild(script)
    //   })
    // }
    // ,
    // NprogressContainer
  },
  computed: {
    isLoggedIn: function () { return this.$store.getters.isLoggedIn },
    user: function () { return this.$store.getters.getUser }, // this.$store.getters.getUser  localStorage.getItem('user')
    headerLogoText: function () { return getEnv('VUE_APP_HEADER_LOGOTEXT') }, // process.env.VUE_APP_HEADER_LOGOTEXT },
    headerLogoPath: function () { return getEnv('VUE_APP_HEADER_LOGOPATH') }, // process.env.VUE_APP_HEADER_LOGOPATH },
    footerText: function () { return getEnv('VUE_APP_FOOTER') } // process.env.VUE_APP_FOOTER }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login');
        });
    }
  },
  created: function () {
    console.log('MODE', process.env.NODE_ENV);
    // this.$http.interceptors.response.use(undefined, function (err) {
    // axios.interceptors.response.use(undefined, function (err) {
    //   console.log('create 2')
    //   return new Promise(function (resolve, reject) {
    //     console.log('create 3')
    //     // console.dir(err)
    //     // console.log(err.config)
    //     // console.log(err.config.__isRetryRequest)
    //     if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    //       // if you ever get an unauthorized, logout the user this.$store.dispatch(AUTH_LOGOUT)
    //       // you can also redirect to /login if needed !
    //       // this.$router.push('/login')
    //       console.log('refresh token ???')
    //     }
    //     throw err
    //   })
    // })
  },
  mounted: function () {
    console.log(process.env);
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    // document.head.appendChild(recaptchaScript)
  }
  // methods: {
  //   clickMenu () {
  //     this.isOpen = !this.isOpen
  //   }
  // }
};
</script>

<style lang="scss">

#app {
    height: 100%;
    width: 100%;
}
.app-skin-base {
  @import './layout/style/scss/style_base.scss';

  color: $base_color_text;
    background: $background_color;
    font-size: $font_size;
    line-height: $line_height;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
  display: flex;
  flex-direction: row;
  height: 100%;
  // background: #FFF;
  background: $app_background;

  .app-sidebar {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
    flex-direction: column;
    max-width: 20%;
    padding: 30px 13px 30px 30px;
    width:250px;
    box-shadow: 8px 0px 18px rgba(0, 0, 0, 0.06);
    z-index: 1;
  }

  .app-wrap {
    overflow: overlay;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .header {
    /* height: 115px; */
    width: 100%;
    background: $header_background;
    color: $header_text_color;
    display: flex;
    align-items: center;
    font-size: 1.1em;
    line-height: 1.2em;
    .app-logo-1 {
        margin: 10px 0 10px 30px;
    }
    .logo-text {
        display: block;
        width: 74px;
        flex-basis: 100%;
        margin-left: 10px;
    }
    .header-controls {
        display: flex;
        align-items: center;
        width:300px;
        a {
        margin-right: 15px;
        color:#fff;
        &:hover {
            color:#c2cfe0;
        }
        }
      }
    &__language-switcher {
      margin-right: 20px;
    }
  }

  main {
    background: #F5F6F8;
    /* width: 100%; */
    flex-basis: 100%;
    /* padding: 60px 50px; */
    &.isLogin {
        padding: 40px 50px;
    }
  }

  .wrap-data {
    padding: 15px 30px;
    background: #fff;
    border-radius: 4px;
    overflow: auto;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .app-sidebar-user {
    display: block;
  }

  .btn-exit {
    border: none;
    /* padding-left: 30px; */
    font-style: normal;
    font-weight: normal;
    color: #112743;
    line-height: 16px;
    cursor: pointer;
    outline:none;
    width: 20px;
    height: 20px;
    background: none;
  }

  .btn-exit:hover path  {
    fill: #fff;
  }

  .login-page {
        width: 100%;
        height: 100%;
        margin: 0;
        display: flex;
        background: #f5f6f8;
  }
}
</style>
