import Vue, { InjectionKey } from 'vue';
import Vuex, { Store } from 'vuex';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export interface State {
  user: AuthUser,
  skin: 'base' | 'fresh'
}

// define injection key
// export const key: InjectionKey<Store<State>> = Symbol();

export default new Vuex.Store({ // <State>
  plugins: [createPersistedState()], // сохраняет состояние после перезагрузки страницы
  state: {
    status: '',
    theme: 'base',
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    refreshTask: 0,
    user: { name: '' }, // JSON.parse(localStorage.getItem('user')) ||
    notice: { type: '', message: '' },
    modalWindow: { data: '' },
    pagination: { large: { onPage: 10, total: 0 }, small: { onPage: 10, total: 0 } }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getNotice: state => state.notice,
    getModalWindow: state => state.modalWindow,
    getPagination: state => state.pagination,
    getUser: state => state.user,
    getRefreshTask: state => state.refreshTask,
    getTheme: state => state.theme
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading';
    },
    auth_success (state, token) {
      localStorage.setItem('token', token.auth);
      localStorage.setItem('refreshToken', token.refresh);
      state.status = 'success';
      state.token = token;
      state.refreshToken = token.refresh;
      // Object.assign(state.user, user)
    },
    auth_error (state) {
      state.status = 'error';
    },
    logout (state) {
      state.status = '';
      state.token = '';
      state.refreshToken = '';
    },
    SET_NOTICE (state, notice) {
      state.notice = notice;
    },
    SET_MODALWINDOW (state, modalWindow) {
      state.modalWindow = modalWindow;
    },
    SET_PAGINATION_LARGE (state, large) {
      state.pagination.large = large;
    },
    SET_PAGINATION_SMALL (state, small) {
      state.pagination.small = small;
    },
    SET_TOKEN (state, token) {
      console.log('SET_TOKEN new:', token);
      state.token = token.auth;
      state.refreshToken = token.refresh;
      localStorage.setItem('token', token.auth);
      localStorage.setItem('refreshToken', token.refresh);
    },
    SET_USER (state: any, user: AuthUser) {
      // state.user = user
      // Object.assign(state.user, user)
      localStorage.setItem('user', JSON.stringify(user));
      state.user.id = user.id;
      state.user.login = user.login;
      state.user.group = user.group;
      state.user.name = user.name;
      state.user.exp = user.exp;
      state.user.refreshExp = user.refreshExp;
    },
    REFRESH_TASK (state, refreshTask) {
      console.log(refreshTask);
      state.refreshTask = refreshTask;
    },
    SET_THEME (state, theme) {
      state.theme = theme;
    }
  },
  actions: {
    login ({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({ url: '/api/auth/login', data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.access_token;
            const refreshToken = resp.data.refresh_token;
            const tokenObj = <JWTToken>jwtDecode(token);
            axios.defaults.headers.common.Authorization = 'Bearer ' + token;
            commit('auth_success', { auth: token, refresh: refreshToken });
            commit('SET_USER', {
              id: tokenObj.id,
              login: resp.data.login,
              group: tokenObj.group,
              name: tokenObj.name
            });
            dispatch('autoRefresh', { auth: token, refresh: refreshToken });
            resolve(resp);
          })
          .catch(err => {
            commit('auth_error');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            reject(err);
          });
      });
    },
    logout ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('logout');
        clearTimeout(getters.getRefreshTask);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        delete axios.defaults.headers.common.Authorization;
        resolve('true');
      });
    },
    addNotice (context, notice) {
      console.log(notice);
      context.commit('SET_NOTICE', notice);
    },
    addModalWindow (context, content) {
      console.log(content);
      context.commit('SET_MODALWINDOW', content);
    },
    changePaginationSmall ({ commit }, small) {
      commit('SET_PAGINATION_SMALL', small);
    },
    changePaginationLarge ({ commit }, large) {
      commit('SET_PAGINATION_LARGE', large);
    },
    updateToken ({ commit, dispatch }, token) {
      commit('SET_TOKEN', token);
      console.log(token);
      dispatch('autoRefresh', token);
    },
    refreshTokens ({ commit, dispatch }, token) {
      console.log('localStorage old token', localStorage.getItem('token'));
      console.log('localStorage old refresh', localStorage.getItem('refreshToken'));
      console.log('refreshTokens old token:', token);
      return axios.post('/api/auth/reftoken',
        {
          refresh_token: token.refresh
        })
        .then(res => {
          if (res.status === 200) {
            commit('SET_TOKEN', { auth: res.data.access_token, refresh: res.data.refresh_token });
            axios.defaults.headers.common.Authorization = 'Bearer ' + res.data.access_token;
            dispatch('autoRefresh', { auth: res.data.access_token, refresh: res.data.refresh_token });
          } else if (res.status === 401) {
            commit('logout');
          }
        });
    },
    autoRefresh ({ state, commit, dispatch }, token: { auth: string }) {
      const { exp } = <JWTToken>jwtDecode(token.auth);
      const date = new Date();
      console.log(exp);
      console.log(Number(new Date()) + 1000);
      const timeUntilRefresh = date.setTime(Number(exp + '000')) - Number(new Date()) + 1000;
      console.log(date.getTime());
      console.log(date.getHours() + ' ' + date.getMinutes() + ' ' + date.getSeconds());
      console.log(timeUntilRefresh);
      console.log(timeUntilRefresh > 0 ? timeUntilRefresh : 1);
      const refreshTask = setTimeout(() => dispatch('refreshTokens', token), timeUntilRefresh > 0 ? timeUntilRefresh : 1);
      // const refreshTask = setTimeout(() => dispatch('refreshTokens', token), 20000)

      commit('REFRESH_TASK', refreshTask); // In case you want to cancel this task on logout
    },
    setTheme ({ state, commit, dispatch }, theme: string) {
      commit('SET_THEME', theme.toLowerCase());
    }
  },
  modules: {
  }
});
