import * as ru from '@/locales/ru-RU.json';
import * as en from '@/locales/en-US.json';

export const languages = [
  {
    name: 'English',
    code: 'en-US',
    code2: 'en',
    locale: en,
    flag: '🇬🇧'
  },
  {
    name: 'Русский',
    code: 'ru-RU',
    code2: 'ru',
    locale: ru,
    flag: '🇷🇺'
  }
];

// Prepare each language for export
const i18nMessages: any = {};
languages.forEach((lang) => {
  i18nMessages[lang.code] = lang.locale;
});
export const messages = i18nMessages;
