<template>
    <div id="nav">
        <router-link to="/projects" class="menu-svg-fill">
            <svg class="main-menu__logo">
                <use xlink:href="../../assets/main-menu-logo.svg#reports" ></use>
            </svg>
            {{$t('mainMenu.projects')}}
        </router-link>
        <router-link to="/bases" class="menu-svg-fill">
            <svg class="main-menu__logo">
                <use xlink:href="../../assets/main-menu-logo.svg#bases" ></use>
            </svg>
            {{$t('mainMenu.knowledgeBase')}}
        </router-link>
        <router-link to="/analisys" class="menu-svg-fill">
            <svg class="main-menu__logo">
                <use xlink:href="../../assets/main-menu-logo.svg#analisys" ></use>
            </svg>
            {{$t('mainMenu.analysis')}}
        </router-link>
        <router-link to="/reports" class="menu-svg-fill">
            <svg class="main-menu__logo">
                <use xlink:href="../../assets/main-menu-logo.svg#analisys2" ></use>
            </svg>
            {{$t('mainMenu.reports')}}
        </router-link>
        <a htref="#" @click="isOpenLog=!isOpenLog" class="parrent-for-sub-menu menu-svg-fill">
            <svg class="main-menu__logo">
                <use xlink:href="../../assets/main-menu-logo.svg#logs" ></use>
            </svg>
            {{$t('mainMenu.logs')}}
            <svg :class="['submenu_arraw', isOpenLog ? 'submenu-open' : 'submenu-close' ]" >
                <use xlink:href="../../assets/main-menu-logo.svg#arrow" ></use>
            </svg>
        </a>
        <transition  name="slide-fade">
            <div v-show="isOpenLog" class="sub-menu" >
                <router-link to="/logs/dublication" class="sub-menu-item">{{$t('mainMenu.logsSub.dublication')}}</router-link>
                <router-link to="/logs/usersSyslog" class="sub-menu-item">{{$t('mainMenu.logsSub.history')}}</router-link>
            </div>
        </transition>
        <a htref="#" @click="isOpenSettings=!isOpenSettings" class="parrent-for-sub-menu menu-svg-fill">
            <svg class="main-menu__logo">
                <use xlink:href="../../assets/main-menu-logo.svg#settings2" ></use>
            </svg>
            {{$t('mainMenu.settings')}}
            <svg :class="['submenu_arraw', isOpenSettings ? 'submenu-open' : 'submenu-close' ]" >
                <use xlink:href="../../assets/main-menu-logo.svg#arrow" ></use>
            </svg>
        </a>
        <transition  name="slide-fade">
            <div v-show="isOpenSettings"  class="sub-menu" >
                <!-- <router-link to="/settings/general" class="sub-menu-item">{{$t('mainMenu.settingsSub.common')}}</router-link> -->
                <router-link to="/settings/users" class="sub-menu-item">{{$t('mainMenu.settingsSub.users')}}</router-link>
            </div>
        </transition>
    </div>
</template>
<!-- src="./MainMenu.locale.json" -->
<i18n  >
    {
      "ru": {
        "mainMenu": {
          "projects": "Проекты"
        }
      },
      "en": {
        "mainMenu": {
          "projects": "Projects"
        }
      }
    }
    </i18n>
<script>
export default ({
  data () {
    return {
      isOpenLog: false,
      isOpenSettings: false
    };
  },
  mounted () { // приложение монтируется
    console.dir(this.$i18n);
  }
});
</script>

<style lang="scss">

// /********************/
// /* MAIN MENU */
// /********************/
// #nav {
//     flex-basis: 100%;
//     a {
//         font-size: 12px;
//         display: flex;
//         align-items: center;
//         margin-bottom: 30px;
//         line-height: 18px;

//         &.menu-svg-fill {
//           fill: #{$main_menu_link_color};
//           &:hover .main-menu__logo {
//             --color_fill: #{$main_menu_link_active};
//             --color_stroke: #{$main_menu_link_active};
//           }
//         }
//         .menu-svg-stroke {
//             stroke: #{$main_menu_link_color};
//         }
//         .main-menu__logo {
//             --color_fill: #{$main_menu_link_color};
//             --color_stroke: #{$main_menu_link_color};
//             width: 22px;
//             height: 22px;
//             position: relative;
//             margin-right: 13px;
//         }

//         &.router-link-exact-active {
//           color: #{$main_menu_link_active};
//           border-radius: 4px;
//             .main-menu__logo {
//               --color_fill: #{$main_menu_link_active};
//               --color_stroke: #{$main_menu_link_active};
//             }
//         }

//         .submenu_arraw {
//           width: 7px;
//           height: 5px;
//           margin-left: 20px;
//           &.submenu-close {
//             transform: rotate(180deg);
//             transition: 0.5s;
//             animation: rotate(180deg) 5s infinite;
//           }
//           &.submenu-open {
//             transform: rotate(0deg);
//             animation: rotate(180deg) 5s infinite;
//             transition: 0.5s;
//           }
//         }
//     }
//     .parrent-for-sub-menu {
//       cursor: pointer;
//     }
//     .sub-menu {
//       margin-top: -20px;
//     }
//     .sub-menu-item {
//       margin-left: 45px;
//       font-size: 11px;
//       margin-bottom: 15px;
//     }
// }

</style>
