<template>
  <div class="app-skin-fresh">
    <div v-if="isLoggedIn" class="app-sidebar">
      <div class="app-sidebar-logo">
        <div class="app-logo-1">
          <!-- <img width="113" height="auto" :src="headerLogoPath" alt=""> -->
          <img width="150" height="auto" :src="headerLogoPath" alt="">
        </div>
        <LanguageSwitcher selectStyle="header__language-switcher"/>
      </div>
      <MainMenu />
      <div v-if="isLoggedIn" class="app-sidebar-bottom">
        <router-link
          v-bind:to="{
            name: 'User Edit',
            params: { id: user.id }
          }"
          class="app-sidebar-user"
        >
          {{ user.name }}
        </router-link>
        <div>
          <button @click="logout" class="btn-exit">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.1429 20.2597H2.85714C2.09938 20.2597 1.37266 19.9548 0.836838 19.412C0.30102 18.8693 0 18.1331 0 17.3655V2.89425C0 2.12665 0.30102 1.39048 0.836838 0.847706C1.37266 0.304929 2.09938 0 2.85714 0L17.1429 0C17.9006 0 18.6273 0.304929 19.1632 0.847706C19.699 1.39048 20 2.12665 20 2.89425V17.3655C20 18.1331 19.699 18.8693 19.1632 19.412C18.6273 19.9548 17.9006 20.2597 17.1429 20.2597ZM7.14286 2.89425C6.76398 2.89425 6.40061 3.04671 6.13271 3.3181C5.8648 3.58949 5.71429 3.95757 5.71429 4.34137V15.9184C5.71429 16.3022 5.8648 16.6702 6.13271 16.9416C6.40061 17.213 6.76398 17.3655 7.14286 17.3655C7.52174 17.3655 7.8851 17.213 8.15301 16.9416C8.42092 16.6702 8.57143 16.3022 8.57143 15.9184V4.34137C8.57143 3.95757 8.42092 3.58949 8.15301 3.3181C7.8851 3.04671 7.52174 2.89425 7.14286 2.89425Z" fill="#C2CFE0"/>
            </svg>
            <span>Выйти</span>
          </button>
        </div>
      </div>
    </div>
    <main :class="[ 'app-wrap', isLoggedIn ? 'isLogin' : '' ]">
      <router-view/>
      <footer class="footer">
        <p>{{ footerText }}</p>
      </footer>
      <NoticeWindow ref="notification" />
      <ModalWindow ref="modalWindow" />
      <vue-progress-bar></vue-progress-bar>
      <!-- <Phone/> -->
        <!-- <div>
          <Phone />
        </div> -->
    </main>
    <Phone/>
  </div>
</template>

<script>
import getEnv from '@/utils/env.js';
import NoticeWindow from '@/components/NoticeWindow';
import ModalWindow from '@/components/ModalWindow';
import MainMenu from '@/components/MainMenu/MainMenu.vue';
import Phone from '@/components/Phone';
import LanguageSwitcher from '@/components/LanguageSwitcher';
// import store from './store'
// import Phone from './components/Phone'

// import ProgressBar from 'vue-simple-progress'
// import NprogressContainer from 'vue-nprogress/src/NprogressContainer'

export default {
  components: {
    NoticeWindow,
    MainMenu,
    Phone,
    // ProgressBar,
    ModalWindow,
    LanguageSwitcher
    // ,
    // Phone: () => {
    //   return new Promise((resolve, reject) => {
    //     // const script = document.createElement('script')
    //     // script.onload = () => {
    //     resolve(import(Phone))
    //     // }
    //     // script.async = true
    //     // script.src = '//mail.communigate.ru/SkinFiles/mail.communigate.ru/samoware/ximssclient.js'
    //     // document.head.appendChild(script)
    //   })
    // }
    // ,
    // NprogressContainer
  },
  computed: {
    isLoggedIn: function () { return this.$store.getters.isLoggedIn },
    user: function () { return this.$store.getters.getUser }, // this.$store.getters.getUser  localStorage.getItem('user')
    headerLogoText: function () { return getEnv('VUE_APP_HEADER_LOGOTEXT') }, // process.env.VUE_APP_HEADER_LOGOTEXT },
    headerLogoPath: function () { return getEnv('VUE_APP_HEADER_LOGOPATH') }, // process.env.VUE_APP_HEADER_LOGOPATH },
    footerText: function () { return getEnv('VUE_APP_FOOTER') } // process.env.VUE_APP_FOOTER }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login');
        });
    }
  },
  created: function () {
    console.log('MODE', process.env.NODE_ENV);
    // this.$http.interceptors.response.use(undefined, function (err) {
    // axios.interceptors.response.use(undefined, function (err) {
    //   console.log('create 2')
    //   return new Promise(function (resolve, reject) {
    //     console.log('create 3')
    //     // console.dir(err)
    //     // console.log(err.config)
    //     // console.log(err.config.__isRetryRequest)
    //     if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    //       // if you ever get an unauthorized, logout the user this.$store.dispatch(AUTH_LOGOUT)
    //       // you can also redirect to /login if needed !
    //       // this.$router.push('/login')
    //       console.log('refresh token ???')
    //     }
    //     throw err
    //   })
    // })
  },
  mounted: function () {
    console.log(process.env);
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    // document.head.appendChild(recaptchaScript)
  }
  // methods: {
  //   clickMenu () {
  //     this.isOpen = !this.isOpen
  //   }
  // }
};
</script>

<style lang="scss">

#app {
    height: 100%;
    width: 100%;
}
.app-skin-fresh {
  @import './layout/style/scss/style_fresh.scss';
  color: $base_color_text;
    background: $background_color;
    font-size: $font_size;
    line-height: $line_height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: row;
  height: 100%;
  background: $app_background;

    .app-sidebar {
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        flex-direction: column;
        max-width: 20%;
        padding: 30px 13px 30px 30px;
        width:250px;
    }

    .app-wrap {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px 0px 0px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    // overflow: scroll;
    overflow-x: hidden;
    }

    header {
    width: 100%;
    background: $header_background;
    color: $header_text_color;
    display: flex;
    align-items: center;
    font-size: 1.1em;
    line-height: 1.2em;
    .app-logo-1 {
        margin: 10px 0 10px 30px;
    }
    .logo-text {
        display: block;
        width: 74px;
        flex-basis: 100%;
        margin-left: 10px;
    }
    .header-controls {
        display: flex;
        align-items: center;
        width:300px;
        a {
        margin-right: 15px;
        color:#fff;
        &:hover {
            color:#fff;
        }
        }
    }
    }

    main {
    background: #F5F6F8;
    flex-basis: 100%;
    &.isLogin {
        padding: 40px 50px 0 50px;
    }
    }

    footer {
        position: absolute!important;
        bottom: 0;
        width: 100%;
    }
    .isLogin footer {
    position: relative!important;
    }

    .slide-fade-enter-active {
    transition: all .3s ease;
    }
    .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
    }

    .app-sidebar-user {
    display: block;
    margin-bottom: 25px;
    }

    .btn-exit {
    background: none;
    border: none;
    font-style: normal;
    font-weight: normal;
    color: #112743;
    line-height: 1.4em;
    cursor: pointer;
    outline:none;
    display: flex;
    padding: 0;
    span {
        margin-left: 4px;
    }
    &:hover svg path  {
        fill: #00aac3;
    }
    &:hover  {
        color: #00aac3;
    }
    }

    .app-sidebar-logo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;
    }

    .login-page {
    background: url("./assets/login_bg.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    display:flex;
    }
}
</style>
