import MessageFormat from 'messageformat';

export default class CustomFormatter {
  _locale;
  _formatter;
  _caches;

  constructor (options: any) {
    this._locale = options?.locale || 'ru-RU';
    this._formatter = new MessageFormat(this._locale);
    this._caches = Object.create(null);
  }

  interpolate (message: string, values: any) {
    let fn = this._caches[message];
    if (!fn) {
      fn = this._formatter.compile(message, this._locale);
      this._caches[message] = fn;
    }
    return [fn(values)];
  }
}
