<template>
  <div id="phone-wrapper" class="phone-wrapper">
    <div class="call-panel">
          <!-- <input id="addCall" class="new-call-button" type="button" value=""> -->
              <svg version="1.1"
                id="addCall"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40" height="40"
                viewBox="0 0 1200 1200"
                enable-background="new 0 0 1200 1200"
                xml:space="preserve">
                  <path fill="#fff" d="M600.001,50C296.244,50,50,296.244,50,600.001C50,903.756,296.244,1150,600.001,1150
                      C903.756,1150,1150,903.756,1150,600.001C1150,296.244,903.756,50,600.001,50z M882.269,738.094l-0.949,2.41l-0.533,4.234
                      c-3.732,58.92-32.451,96.346-55.886,117.37c-13.288,11.923-30.409,19.089-48.213,20.171c-4.65,0.277-9.422,0.421-14.178,0.421
                      c-54.835,0-166.218-19.382-296.028-149.187c-137.478-137.473-152.21-253.355-148.761-310.196
                      c1.082-17.809,8.243-34.936,20.166-48.224c21.03-23.429,58.461-52.143,117.376-55.881l2.586-0.165l4.074-1.328
                      c0.698-0.277,1.53-0.421,2.463-0.421c11.907,0,36.146,20.662,56.104,55.241c10.771,18.641,18.188,37.634,20.897,53.47
                      c2.442,14.343,0.043,22.256-2.762,24.677l-2.869,2.49l-1.861,3.311c-16.642,29.551-41.958,42.881-60.258,48.858l-33.864,11.016
                      l9.038,21.803c18.348,44.241,75.097,152.813,205.478,205.324l29.38,11.976l10.99-29.129c5.983-18.284,19.302-43.574,48.805-60.205
                      l3.429-1.936l2.533-3.002c5.444-6.457,36.189-5.865,78.041,18.31C866.796,702.215,885.895,728.928,882.269,738.094z"></path>
              </svg>
              <div id="phoneWindow" class="calls-list"></div>
    </div>
    <div style="display:none;" class="test-media">
                  <div id="outputPanel" class="test-info"></div>
                  <video id="selfVideoContainer" class="video-container" autoplay="true" muted=""> </video>
                  <video id="remoteVideoContainer" class="video-container" autoplay="true"> </video>
                  <div id="remoteStreams"></div>
    </div>
  </div>
</template>

<script>
// import JSONSession from 'https//mail.unistar.ru/SkinFiles/unistar.ru/webcall/jsonsession_min.js'
// import Dialer from 'https://mail.unistar.ru/SkinFiles/unistar.ru/webcall/dialer_min.js'
// import CallPanel from 'https//mail.unistar.ru/SkinFiles/unistar.ru/webcall/call-panel-egais.js'
// import webcall from '../modules/phone/webcall'

// import test from '../modules/phone/test'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  data () {
    return {
    };
  },
  mounted: function () {
    // const insertScript = document.createElement('script')
    // insertScript.appendChild(webcall)
    // test()
    const insertScript = document.createElement('script');
    insertScript.setAttribute('src', '//mail.communigate.ru/SkinFiles/mail.communigate.ru/samoware/ximssclient.js');
    document.body.appendChild(insertScript);
    insertScript.setAttribute('src', '//mail.unistar.ru/SkinFiles/unistar.ru/webcall/initBrowsers.js');
    document.body.appendChild(insertScript);
    insertScript.setAttribute('src', '//mail.unistar.ru/SkinFiles/unistar.ru/webcall/webcall-egais.js');
    document.body.appendChild(insertScript);
  }
};
</script>

<style >
  #addCall {
    /* background: url("./phone.png") no-repeat scroll 0 0 transparent;
    background-size: cover; */
    border: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    outline: none;
  }
  .end-call {
    cursor: pointer;
    transform: scaleX(-1);
  }
  div.call-panel {
    /* display: flex; */
    display: block;
    flex-direction: column;
    /* border-right: 1px solid darkslategrey; */
    /* padding-right: 10px; */
    /* position: relative;
    float: right; */
  }
  .new-call-button {
    margin-bottom: 20px;
  }
  .calls-list {
    display: flex;
    flex-direction: column;
    /* border: 1px solid #000; */
    border:none;
    border-radius: 5px;
    /* padding: 15px 15px 0 15px; */
    /* position: absolute; */
    position: relative;
    float: right;
    bottom: 0;
    right: 0;
    display: none;
  }
  .call-item {
    /* border-bottom: 1px solid gray; */
    /* margin-bottom: 20px; */
  }
  .call-wrapper {
    /* margin-bottom: 10px; */
  }
  .row {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }

  .row :not(:first-child) {
    margin-left: 10px;
  }

  .test-controls {
    display: flex;
    flex-direction: column;
  }
  .test-controls > * {
    /* margin-top: 5px; */
  }
  .input-fill-available {
    width: -webkit-fill-available;
  }
  .test-media {
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
  .media-remote-video {
    min-width: 0;
    min-height: 0;
    max-height: 100px;
  }
  .video-container {
    min-width: 0;
    min-height: 0;
  }

  .test-info {
    font-size: 10px;
    white-space: pre;
    max-height: 200px;
      overflow: auto;
  }
</style>
