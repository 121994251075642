import axios from 'axios';
import LocalStorageService from './store/LocalStorageService';
import router from './router';
import store from './store';
// import Vue from 'vue'

// Vue.prototype.$http = axios
// const token = localStorage.getItem('token')
// if (token) {
//   Vue.prototype.$http.defaults.headers.common.Authorization = 'Bearer ' + token
// }

// LocalstorageService
const localStorageService = LocalStorageService.getService();
const refTokenURL = '/api/auth/reftoken';
const rToken = localStorageService.getAccessToken();
const rRefresh = localStorageService.getRefreshToken();
console.log(localStorageService, localStorageService);
console.log(rToken, rToken);
console.log(rRefresh, rRefresh);

if (rToken) {
  store.dispatch('autoRefresh', { auth: rToken, refresh: rRefresh });
}

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error);
  });

// Add a response interceptor

axios.interceptors.response.use((response) => {
  return response;
}, function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url === refTokenURL && window.location.pathname !== '/login') {
    clearTokenLogout();
    return Promise.reject(error);
  }

  console.log('status', error.response.status);
  console.log('originalRequest.url', originalRequest.url);
  console.log('originalRequest._retry', originalRequest._retry);

  if ((error.response.status === 401 || error.response.status === 400) && !originalRequest._retry && originalRequest.url !== '/api/auth/login' && window.location.pathname !== '/login') {
    originalRequest._retry = true;
    return axios.post(refTokenURL,
      {
        refresh_token: localStorageService.getRefreshToken()
      })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          updateTokens(res.data.access_token, res.data.refresh_token);
          originalRequest.headers.Authorization = 'Bearer ' + res.data.access_token;
          return axios(originalRequest);
        } else if (res.status === 401 || (res.status === 400 && res.data.message === 'Token is expired')) {
          clearTokenLogout();
        }
      });
  }
  return Promise.reject(error);
});

function updateTokens (token, refreshToken) {
  store.dispatch('updateToken', { auth: token, refresh: refreshToken });
  localStorageService.setToken({ token: token, refreshToken: refreshToken });
  axios.defaults.headers.common.Authorization = 'Bearer ' + token;
}

function clearTokenLogout () {
  localStorageService.clearToken();
  store.dispatch('logout')
    .then(() => {
      router.push('/login');
    });
}
