<template>
  <label :class="['s-select','label', selectStyle, length && `label_length_${length}`, theme && `s-select--theme--${theme}`]">
    <span v-if="label" class="label_text">{{ label }}</span>
    <select
      v-model="selectValue"
      :class="['s-select__input']"
      :ref="setRef"
      :name="name"
      :id="id"
    >
      <option
        v-for="(item, index) of list"
        :key="index"
        :item="item"
        :value="item.value"
      >
        {{ item.content }}
      </option>
    </select>
    <div
      :class="['s-select__head', showList&&'open']"
      @click="handleShowList()"
    >
      <span
        v-if="selectValue"
        :class="['s-select__head_text']"
      >{{ selectContent }}</span>
      <span
        v-else
        :class="['s-select__head_text']"
      >Выберите</span>
    </div>
    <ul
      class="s-select__list"
      v-show="showList"
    >
      <li
        v-for="(item, index) of selectList"
        :key="index"
        :item="item"
        class="s-select__item"
        @click="handleClick(item.value)"
      >
        {{ item.content }}
      </li>
    </ul>
  </label>
</template>

<script>

export default {
  inheritAttrs: false,
  // model: {
  //   prop: "modelValue",
  //   event: "change"
  // },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    list: {
      type: [Array],
      default: () => {
        return [{
          value: null,
          content: null
        }];
      }
    },
    selectStyle: {
      type: String,
      default: 'select'
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: 'input'
    },
    viewTag: {
      type: String,
      default: 'InputCheckbox'
    },
    setRef: {
      type: String,
      default: 'checkbox'
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'green'
    },
    size: {
      type: String,
      default: 'normal'
    },
    id: {
      type: String,
      default: ''
    },
    error: {
      type: Object,
      default: () => {
        return {
          status: false,
          message: ''
        };
      }
    },
    length: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showList: false,
      border: true // input border
      // currentView: "InputText"
    };
  },
  computed: {
    // theme () {
    //   return null;
    // },
    selectValue: {
      get () {
        return this.value;
      },
      set (value) {
        console.log(value);
        this.$emit('input', value);
        this.$emit('selected', value);
      } // @input="$emit('input', $event.target.value)"
    },
    selectContent () {
      const findSelected = this.list.find((el) => el.value === this.selectValue);

      return findSelected.content;
    },
    selectList () {
      return this.list.filter(el => el.value !== this.selectValue);
    }
    // isChecked() {

    //   if (this.modelValue instanceof Array) {
    //     return this.modelValue.includes(this.value);
    //   }

    //   // Note that `true-value` and `false-value` are camelCase in the JS
    //   return this.modelValue === this.trueValue;
    // }
  },
  methods: {
    handleClick (value) {
      this.selectValue = value;
      this.showList = !this.showList;
    },
    handleShowList () {
      this.showList = !this.showList;
    }
    //   updateInput(event) {
    //     console.log(event);

    //     let isChecked = event.target.checked;

    //     if (this.modelValue instanceof Array) {
    //       let newValue = [...this.modelValue];

    //       if (isChecked) {
    //         newValue.push(this.value);
    //       } else {
    //         newValue.splice(newValue.indexOf(this.value), 1);
    //       }
    //       this.$emit("change", newValue);
    //     } else {
    //       this.$emit("change", isChecked ? this.trueValue : this.falseValue);
    //     }
    //   }
  },
  mounted: function () {
    // this.$refs[this.setRef].type = this.type;
  }
};
</script>

<style lang="scss" scoped>
// @import "./styles/label.scss";

.s-select {
  position: relative;
  display: block;
  color: var(--form_color_text);
  min-width: 40px;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;

  &--theme--fresh {
      & .s-select__head {

        &::after {
          background: get-icon( 'btn-arrow-down', map-get( $theme-map-fresh, '--main_menu_link_color') ) no-repeat scroll 100% 50% transparent;
          background-size: 12px 9px;
        }
      }
  }

  &--theme--base {
      & .s-select__head {

        &::after {
          background: get-icon( 'btn-arrow-down', map-get( $theme-map-base, '--main_menu_link_color') ) no-repeat scroll 100% 50% transparent;
          background-size: 12px 9px;
        }
      }
  }

  & .s-select__head {
        // width: 100%;
        // max-width: 100%;
        border: none;
        border-bottom: 3px solid var(--border_color);
        // map-get($colors, border-form);
        padding: 8px 0px 3px 0;
        font-size: 14px;
        line-height: 16px;
        color: var(--form_color_text);
        //  map-get($colors, primary-bright);
        cursor: pointer;

        &_text {
          display: block;
          min-width: 35px;
        }

        &::after {
          width: 12px;
          height: 15px;
          position: absolute;
          right: 0px;
          bottom: 30%;
          transform: translateY(50%);
          content: '';
          display: block;
          transition: .2s ease-in;
        }

        &.open::after {
          transform: translateY(50%) rotate(180deg);
        }
  }

  &__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 5px 5px;;
    margin-top: 5px;
    max-height: 205px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--form_color_text);
    // map-get($colors, primary-bright);;
    scrollbar-color: dark;
    scrollbar-width: thin;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: #F8F9FA;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px 0px 5px 5px;;
      background-color: #D9D9D9;
    }

    & .s-select__item {
      position: relative;
      border-top: 1px solid var(--border_color);
      // map-get($colors, border-form);
      padding: 8px 5px;
      cursor: pointer;
      list-style-type: none;

      &:hover {
        background-color: rgba(224, 229, 231, 0.5);
      }
    }
  }
}
</style>
