<template>
  <form class="login-form" action="" @submit.prevent="login" novalidate="true">
      <img class="login-form__img" width=257 height=auto :src="logoPath" alt="">
      <h1 class="login-form__h1">{{ $t('loginPage.header') }}</h1>
      <input
        required
        v-model="userLogin"
        type="email"
        name=userLogin
        :placeholder="$t('loginPage.placeholder.login')"
        autocomplete="email"
        class="input login-form__input login-form__input-text"
        :class="{error: errors.login}"
      >
      <input
        required
        v-model="password"
        type="password"
        name=password
        :placeholder="$t('loginPage.placeholder.password')"
        autocomplete="password"
        v-on:keyup.enter="login"
        class="input login-form__input login-form__input-text"
        :class="{error: errors.password}"
        >
      <!-- <div class="form-remember">
          <input class="login-form__input" login-form__input type="checkbox" id="login-remember" name=remember><label class="login-form__label" for="login-remember">Запомнить меня</label>
      </div> -->
      <input class="btn btn-1" type="submit" :value="$t('button.signin')">
  </form>
</template>

<script>
// import _ from 'lodash'
import { debounce } from 'debounce';
import getEnv from '../utils/env.js';

export default {
  data () {
    return {
      userLogin: '',
      password: '',
      errors: {
        login: false,
        password: false
      }
    };
  },
  computed: {
    logoPath: function () { return getEnv('VUE_APP_LOGIN_LOGOPATH') } // function () { return process.env.VUE_APP_LOGIN_LOGOPATH }
  },
  watch: {
    userLogin: function () {
      this.debouncedCheckLogin();
    },
    password: function () {
      this.debouncedCheckPassword();
    }
  },
  created: function () {
    this.debouncedCheckLogin = debounce(this.checkLogin, 500); // _.debounce(this.validEmail, 500)
    this.debouncedCheckPassword = debounce(this.checkPassword, 500);
  },
  methods: {
    login: function () {
      const username = this.userLogin;
      const password = this.password;

      if (!this.errors.login && !this.errors.password && username && password) {
        this.$store.dispatch('login', { username, password })
          .then(() => this.$router.push('/'))
          .catch(err => {
            this.$parent.$parent.$refs.notification.showNotice({
              type: 'Error',
              message: err.response.status === 401 ? this.$t('messages.wrongCredentailes') : err
            });
          });
      }
    },
    checkLogin () {
      !this.validEmail(this.userLogin) ? this.errors.login = true : this.errors.login = false;
    },
    checkPassword () {
      !this.password ? this.errors.password = true : this.errors.password = false;
    },
    validEmail (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
};
</script>

<style lang="scss">
// .login-form {
//     background: #FFFFFF;
//     box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
//     border-radius: 4px;
//     padding: 30px;
//     margin:auto;
//     display: flex;
//     flex-direction: column;
//     width:300px;
//   &__img {
//     text-align: center;
//     margin: 20px auto;
//   }
//   &__h1 {
//     text-align: center;
//   }
//   &__label {
//     font-size: 0.8em;
//     line-height: 1.2em;
//     color: $form_color_label;
//   }
//   &__input {
//     margin-bottom: 15px;
//     border: 1px solid $border_color;
//     box-sizing: border-box;
//     border-radius: 6px;
//     outline:none;
//     &-text  {
//       height: 45px;
//       width: 100%;
//       padding: 12px 10px 14px 10px;
//       color: $form_color_text;
//       position: relative;

//       &::-moz-placeholder,
//       &::-webkit-input-placeholder,
//       &:-ms-input-placeholder,
//       &::-ms-input-placeholder,
//       &::-ms-input-placeholder,
//       &:-ms-input-placeholder,
//       &::placeholder,
//       &::-webkit-input-placeholder { color: $form_color_placeholder; }

//       &.error {
//         border-color: #FF3C5F;
//         /* background: url('../assets/icon_login_error.svg') no-repeat scroll 0 0 transparent; */
//         background-image:url(../assets/icon_login_error.svg);
//         background-repeat: no-repeat;
//         background-position: right 11px top 12px;
//         padding: 12px 40px 14px 10px;
//       }
//     }
//   }
// }

// .login-form input[name=remember] {
//     margin: 0 7px;
// }

// #login-remember {
//   position: absolute;
//   z-index: -1;
//   opacity: 0;
// }

// #login-remember+label {
//   display: inline-flex;
//   align-items: center;
//   user-select: none;
// }

// #login-remember+label::before {
//   content: '';
//   display: inline-block;
//   width: 1em;
//   height: 1em;
//   flex-shrink: 0;
//   flex-grow: 0;
//   border: 1px solid #adb5bd;
//   border-radius: 0.25em;
//   margin-right: 0.5em;
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 50% 50%;
// }

// #login-remember:checked+label::before {
//   border-color: #0B5CA6;
//   background-color: #0B5CA6;
//   color: #fff;
//   background-image: url("../assets/icon_daw.svg");
// }

// .form-remember {
//     text-align: right;
//     margin-bottom: 25px;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
// }
</style>
