<template>
  <div class="login-page">
    <LoginForm :msg="$t('loginPage.welcome')"/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LoginForm
  }
};
</script>
