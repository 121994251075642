<template>
    <transition name="modal">
        <div class="modal-mask" v-show="content!==''">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <slot name="header">
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                        </slot>
                        <p v-html="content"></p>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <!-- <button class="modal-default-button" @click="$emit('close')">
                                OK
                            </button> -->
                            <button class="btn btn-1" @click="closeModal">OK</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  data: function () {
    return {
      item: null,
      visible: false
    };
  },
  computed: {
    content: {
      get () {
        return this.$store.state.modalWindow.data;
      },
      set (data) {
        return this.$store.commit('SET_MODALWINDOW', { data: data });
      }
    }
  },
  methods: {
    closeModal: function () {
      this.visible = false;
      this.item = null;
      this.clear();
    },
    convertNewLinesToBr (str) {
      str = str.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return str;
    },
    show (data) {
      console.dir(data);
      data = this.convertNewLinesToBr(data);
      this.$store.dispatch('addModalWindow', { data: data });
    },
    clear () {
      this.$store.dispatch('addModalWindow', { data: '' }); // null
    }
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 530px;
}

.modal-header h3 {
  margin-top: 0;
  /* color: #42b983; */
}

.modal-body {
  margin: 20px 0;
  overflow: scroll;
  max-height: 450px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
