import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
// import NProgress from 'vue-nprogress'
import store from '../store';

Vue.use(VueRouter);
// Vue.use(NProgress)

const routes = [ // Array<RouteConfig>
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/chats',
    name: 'Chats',
    component: () => import(/* webpackChunkName: "about" */ '../views/Chats.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectsList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/new',
    name: 'New Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectsEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/edit/:id',
    name: 'Edit Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectsEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bases',
    name: 'Bases',
    component: () => import(/* webpackChunkName: "about" */ '../views/BasesList.vue'),
    meta: {
      requiresAuth: true,
      showProgressBar: true
    }
  },
  {
    path: '/bases/new',
    name: 'New Base',
    component: () => import(/* webpackChunkName: "about" */ '../views/BasesBaseEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bases/:id/edit',
    name: 'Edit Base',
    component: () => import(/* webpackChunkName: "about" */ '../views/BasesBaseEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bases/:id/item/new',
    name: 'New Item',
    component: () => import(/* webpackChunkName: "about" */ '../views/BasesBaseItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bases/:id/item/:idItem/edit',
    name: 'Edit Item',
    component: () => import(/* webpackChunkName: "about" */ '../views/BasesBaseItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bases/:id/items',
    name: 'List Items Base',
    component: () => import(/* webpackChunkName: "about" */ '../views/BasesBaseItemsList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/analisys',
    name: 'Analisys',
    component: () => import(/* webpackChunkName: "about" */ '../views/Analisys.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reports.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings/General.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/general',
    name: 'General',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings/General.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/classes',
    name: 'Classes',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings/Classes.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings/Users.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/users/item',
    name: 'User New',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings/UsersItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/users/item/:id',
    name: 'User Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings/UsersItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/entities',
    name: 'Entities',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings/Entities.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/entities/item',
    name: 'Entities Item Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings/EntitiesItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logs/dublication',
    name: 'Logs Dublication',
    component: () => import(/* webpackChunkName: "about" */ '../views/Logs/Dublication.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logs/usersSyslog',
    name: 'Users Syslog',
    component: () => import(/* webpackChunkName: "about" */ '../views/Logs/UsersSyslog.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/PageNotFound.vue')
  }, {
    path: '*',
    redirect: '/404'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    console.log('start NProgress');
    // console.log(NProgress)
    // NProgress.start()
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  console.log('stop NProgress');
  // console.log(NProgress)
  // NProgress.done()
  // NProgress.remove()
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('fff1');
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    console.log('fff2');
    next();
  }
});

export default router;
