<template>
    <transition name="fade">
        <div
            v-if="(showErr&&message)||showNotification&&message"
            v-bind:class="{
                'error': showErr,
                'notice': showNotification
            }"
            class="allertMessage"
        >
            <!-- <div
                v-if="(showErr&&message)"
            >Ошибка: <br/></div> -->
            <div v-html="message" class="text"></div>
        </div>
        <div
            v-if="(showErr&&!message)||showNotification&&!message"
            class="allertMessage"
        >
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'NoticeWindow',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: false
    },
    wait: {
      type: Number,
      default: 5000,
      required: false
    }
  },
  computed: {
    showErr () {
      return this.$store.state.notice.type === 'Error';
    },
    showNotification () {
      return this.$store.state.notice.type === 'Notice';
    },
    message: {
      get () {
        return this.$store.state.notice.message;
      },
      set (message) {
        return this.$store.commit('SET_NOTICE', { type: 'Error', message });
      }
    }
  },
  methods: {
    showNotice (notice) {
      console.dir(notice);
      this.$store.dispatch('addNotice', notice);
      setTimeout(() => this.clear(), this.wait);
    },
    clear () {
      this.$store.dispatch('addNotice', { type: '', message: '' }); // null
    }

  },
  mounted () { // приложение монтируется
  }
};
</script>

<style scoped>
    .fade-enter-active{
        transition: opacity .9s;
    }
    .fade-enter{
        opacity:0;
    }
    .fade-enter-to{

    }
    .fade-leave{

    }
    .fade-leave-active{
        transition: opacity .9s;
    }
    .fade-leave-to{
        opacity:0.9;
    }
    .allertMessage{
        display: block;
        text-align: center;
        color: #fff;
        position: fixed;
        /* background-color: #f1453c; */
        padding:18px;
        width: 400px;
        /* left:0; */
        top: 82px;
        right: 17px;
        border-radius: 5px;
        /* display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; */
    }

    .allertMessage.error {
        background-color: #f1453c;
    }

    .allertMessage.notice {
        background-color: #79c666;
    }
    .allertMessage .text {
      /* text-align: left; */
      /* margin-top: 0; */
    }

</style>
