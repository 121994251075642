import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from 'axios';
import VueProgressBar from 'vue-progressbar';
import VueSessionStorage from 'vue-sessionstorage';
import i18n from './i18n';

// import VueI18n from 'vue-i18n';

// import './layout/style/normalize.css'
import '@/layout/style/style.css';

import './axios';
import { mapGetters } from 'vuex';

import getEnv from '@/utils/env.js';
// import 'nprogress/nprogress.css'
// import NProgress from 'vue-nprogress'

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = 'Bearer ' + token;
}

Vue.mixin({
  data () {
    return {
      isMobile: window.innerWidth < 769
    };
  },
  computed: {
    theme: function () { return store.state.theme } // this.$store.getters.getSkin
  }
});

// Vue.prototype.$http.interceptors.request.use(function (config) {
//   const token = this.$store.state.token
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`
//   }
//   return config
// }, function (err) {
//   return Promise.reject(err)
// })

// Vue.prototype.$http.interceptors.response.use(undefined, function (err) {
//   console.log('create 2')
//   return new Promise(function (resolve, reject) {
//     console.log('create 3')
//     // console.dir(err)
//     // console.log(err.config)
//     // console.log(err.config.__isRetryRequest)
//     if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
//       // if you ever get an unauthorized, logout the user this.$store.dispatch(AUTH_LOGOUT)
//       // you can also redirect to /login if needed !
//       // this.$router.push('/login')
//       console.log('refresh token ???')
//     }
//     throw err
//   })
// })

Vue.use(VueProgressBar, {
  color: 'rgb(121 198 102)',
  failedColor: '#f1453c',
  height: '4px'
});
Vue.use(VueSessionStorage);

// Vue.use(VueI18n);
// const options = {
//   latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
//   router: true, // Show progressbar when navigating routes, default: true
//   http: false // Show progressbar when doing Vue.http, default: true
// }
// Vue.use(NProgress, options)
// const nprogress = new NProgress()

new Vue({
  i18n,
  router,
  store,
  // nprogress,
  render: h => h(App)
}).$mount('#app');
