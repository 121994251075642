<template>
  <div v-if="$i18n.availableLocales.length > 1 && languagesView.length > 1"
    :class="[selectStyle, 'lnaguage-switcher']">
    <!-- :class="$style.root__field" -->
    <FormSelect
        v-model="$i18n.locale"
        name="language"
        :list="languagesView"
        select-style=""
      />
  </div>
</template>

<script >
import { languages } from '@/locales/index';
import FormSelect from '@/components/form/FormSelect.vue';

export default {
  name: 'SelectLocale',
  components: {
    FormSelect
  },
  props: {
    selectStyle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      languagesView: []
    };
  },
  mounted () {
    console.log(languages);
    this.languagesView = languages.map(l => { return { value: l.code, content: l.flag } });

    // <option
    //     v-for="(lang, i) in languages"
    //     :key="`lang-${i}`"
    //     :value="lang.code"
    //   >
    //     {{ lang.code2 }}
    //   </option>
  }
};
</script>

<style lang="scss">
.lnaguage-switcher {

  .s-select {
    min-width: 25px;

    &__head {
      border-bottom: none!important;

      &__text {
        min-width: 25px;
      }

      &::after {
        display: none!important;
      }
    }

    &__item {
      padding: 2px 2px!important;
    }
  }
  // & .s-select .s-select__head {
  //   border-bottom: none;

  //   &::after {
  //     display: none;
  //   }
  // }

  // & .s-select .s-select__item {

  //   padding: 2px 2px;
  // }
}
</style>
