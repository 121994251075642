<template>
  <div id="app">
    <layout-fresh v-if="appSkin==='fresh'"/>
    <layout-base v-else />
  </div>
</template>

<!-- <i18n src="./locales/en-US.json" locale="en-US"></i18n>
<i18n src="./locales/ru-RU.json" locale="ru-RU"></i18n> -->

<script>
import getEnv from '@/utils/env.js';
import LayoutBase from '@/LayoutBase.vue';
import LayoutFresh from '@/LayoutFresh.vue';

export default {
  components: {
    LayoutBase,
    LayoutFresh
  },
  data () {
    this.$i18n.locale = 'ru-RU';
    return { locale: 'ru-RU' };
  },
  watch: {
    locale (val) {
      this.$i18n.locale = val;
    }
  },
  computed: {
    isLoggedIn: function () { return this.$store.getters.isLoggedIn },
    user: function () { return this.$store.getters.getUser }, // this.$store.getters.getUser  localStorage.getItem('user')
    headerLogoText: function () { return getEnv('VUE_APP_HEADER_LOGOTEXT') }, // process.env.VUE_APP_HEADER_LOGOTEXT },
    headerLogoPath: function () { return getEnv('VUE_APP_HEADER_LOGOPATH') }, // process.env.VUE_APP_HEADER_LOGOPATH },
    footerText: function () { return getEnv('VUE_APP_FOOTER') }, // process.env.VUE_APP_FOOTER }
    appSkin: function () { return getEnv('VUE_APP_SKIN') }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login');
        });
    }
  },
  created: function () {
    console.log('MODE', process.env.NODE_ENV);
    // this.$http.interceptors.response.use(undefined, function (err) {
    // axios.interceptors.response.use(undefined, function (err) {
    //   console.log('create 2')
    //   return new Promise(function (resolve, reject) {
    //     console.log('create 3')
    //     // console.dir(err)
    //     // console.log(err.config)
    //     // console.log(err.config.__isRetryRequest)
    //     if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    //       // if you ever get an unauthorized, logout the user this.$store.dispatch(AUTH_LOGOUT)
    //       // you can also redirect to /login if needed !
    //       // this.$router.push('/login')
    //       console.log('refresh token ???')
    //     }
    //     throw err
    //   })
    // })
  },
  mounted: function () {
    this.$store.dispatch('setTheme', this.appSkin);
    console.log(process.env);
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    // document.head.appendChild(recaptchaScript)
  }
  // methods: {
  //   clickMenu () {
  //     this.isOpen = !this.isOpen
  //   }
  // }
};
</script>

<style lang="scss">
@import "@/layout/style/scss/style2";

</style>
